import './mediumarticletile.css'
import { strapiUrl } from '../../quick_access_links'
import { Link } from 'react-router-dom'

import React from 'react'

const MediumArticleTile = ( {date, body, title, image, id, alttext}) => {
  let largertextbody = body.substring(50,300)
    
  return (
    <div className='dcs__mediumarticletile'>
      <div className='dcs__mediumarticletile-line' />
      <div className='dcs__mediumarticletile-container'>
        
      <img src={strapiUrl + image} alt={alttext}/>

        <div className='dcs__mediumarticletile-container_content'>
          <h5>{date}</h5>
          <Link to={`/articles/${id}`}><h2>{title}</h2></Link>
          <p>{body.substring(0,50)}<span>{largertextbody}</span>...</p>
        </div>
      </div>
    </div>
  )
}

export default MediumArticleTile