import './socialsection.css'
import React from 'react'
import { FaSoundcloud, FaFacebook } from 'react-icons/fa'
import { RiInstagramFill } from 'react-icons/ri'

const SocialSection = ( scrollPos ) => {

  return (
    <section className='dcs__socialsection' id='audiosamples' >
        <div className='dcs__socialsection-container'  >
            <a href='https://soundcloud.com/dcstudionz' target='_blank' rel="noreferrer"><FaSoundcloud className='dcs__socialsection-container_icon' /></a>
            <a href='https://www.facebook.com/profile.php?id=100087385452635' target='_blank' rel="noreferrer"><FaFacebook className='dcs__socialsection-container_icon' /></a>
            <a href='https://www.instagram.com/dcstudionz/' target='_blank' rel="noreferrer"><RiInstagramFill className='dcs__socialsection-container_icon' /></a>
        </div>
    </section>
  )
}

export default SocialSection