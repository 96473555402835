import './bookingform.css'
import React, {useState, useRef} from 'react'
import ReactDOM from 'react-dom'
import logo from '../../assets/logo-whitetext-circle.png'
import { RiCloseFill } from 'react-icons/ri'
import DatePicker from 'react-multi-date-picker'
import 'animate.css'
// This import is related to the Email.js service
import emailjs from '@emailjs/browser';

// Imports the isOpen state and onClose functions
const BookingForm = ({ open, children, onClose  }) => {
// this section will be related to the email.js service
  const form = useRef();
  const [sentMessage, setSentMessage] = useState(false)

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_ez58638', 'template_d4fivxg', form.current, 'NtwkeeNRtQPVrXqg0')
      .then(() => {
        setSentMessage(true);
      }, (error) => {
        console.log(error.text);
      });
    e.target.reset()
  };



  const [value, setValue] = useState(new Date())
  // If closed return nothing
  if (!open) return null


  // Renders the component into a completely separate div (#portal) in the index.js file
  // **Retains parent - child relationship because of createPortal 
  return ReactDOM.createPortal(
    <div className='dcs__bookingform'>
      <div className='dcs__bookingform-blur' onClick={onClose}/>  
        <div className='dcs__bookingform-container animate__animated animate__zoomIn' >
          <RiCloseFill onClick={onClose} />
          <img src={logo} alt='DC Studio logo'/>
          <h1>Booking Form</h1>
          {!sentMessage
          ? <form
            className='dcs__bookingform-container_form'
            action="submit"
            ref={form}
            onSubmit={sendEmail}>

            <input type="text" placeholder='name'
              name='user_name' required />

            <input type="email" placeholder='email'
              name='user_email' required />

            <input type="phone" placeholder='phone'
              name='user_phone' />

            <textarea cols="30" rows="5" placeholder='description of project'
              name='user_project' required />

            {/* This can be activated if client wishes to pay for email attachments
          <p>Upload a demo!</p>          
          <input  type="file"
          name='user_demo' className='chooseFile'/> */}

            <p>Please select the dates you would like to record:</p>
            <DatePicker value={value} onChange={setValue} range
              name='user_dates' className='datepicker' />

            <button type='submit' value='send' >SEND</button>
            {/* <p className={sentMessage ? 'dcs__bookingform-container_form-sentmessage' : 'dcs__bookingform-container_form-sentmessage hide'}>Message sent! I will get back to you as soon as I can!</p> */}

            </form>
          : <div className='dcs__bookingform-container_thankyou'><p>Booking sent! <br />I will get back to you as soon as I can!</p></div>
          }
        </div>
    </div>,

    document.getElementById('portal')
  )
}

export default BookingForm