import './audiosamplesection.css'
import React from 'react'
import { BookNowButton } from '../../components/component_index'
import ReactPlayer from 'react-player'
import useFetch from '../../hooks/useFetch'

const AudioSampleSection = () => {
  const { loading, error, data } = useFetch('https://dcstudiobackend-production.up.railway.app/api/audio-example-description');
    if (loading) return <p>Loading...</p>
    if (error) return <p>Error</p>
    console.log(data)
    const description = data.data.attributes.text
  return (
    <section className='dcs__audiosamplesection'>
        <div className='dcs__audiosamplesection-container'>
            <div className='dcs__audiosamplesection-container_content'>
                <h2>Audio Samples</h2>
                <p>{description}</p>
                <BookNowButton text='Like what you hear?' />
            </div>
            <div className='dcs__audiosamplesection-container_audioplayer'>
                <ReactPlayer url='https://soundcloud.com/dcstudionz/sets/dc-studio-examples?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing' />
            </div>
        </div>
    </section>
  )
}

export default AudioSampleSection