import './smallarticletile.css'

import React from 'react'

const SmallArticleTile = ({ title, date, id, image, alttext }) => {

  

  return (
    <div className='dcs__smallarticletile' role='article'>
      <div className='dcs__smallarticletile-container' data-testid="articleTile">
        <img src={image} alt={alttext} />
        
        <div className='dcs__smallarticletile-container_content'>
          <div className='dcs__smallarticletile-container_content-date'>
            <p>{date.toString().substring(0,16)}</p>
          </div>

          <div className='dcs__smallarticletile-container_content-title'>
            <h4>{title}</h4>
          </div>
        </div>

      </div>
    </div>
  )
}

export default SmallArticleTile