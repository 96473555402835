import './landingsection.css'
import React from 'react'
import heroImage from '../../assets/hero-image.jpg'
import 'animate.css'
import logo from '../../assets/logo-whitetext.png'
import { BookNowButton } from '../../components/component_index'

const LandingSection = () => {
  return (
      <section className='dcs__landing-section' id='home'>
        <div className='dcs__landing-section_container '>
 
            <div className='dcs__landing-section_container-blur' />
            {/* <img src={vector} alt="Blurred vector image over top of hero image" className='dcs__landing-section_container-vector' /> */}
            <img src={heroImage} alt='Photograph of Dan in the Studio, working on a project' className='dcs__landing-section_container-heroimg' /> 
            <div className='dcs__landing-section_container-title'>
                <h1>Welcome to</h1>
                <img src={logo} alt='DC Studio Logo' />
                <p>Your friendly, local recording studio<br/><span>Te Awamutu, Waikato</span></p>
            </div>  
          <BookNowButton text="Ready to take your ideas to the next level?" />
        </div>
      </section>
  )
}

export default LandingSection