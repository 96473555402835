import './App.css'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { HomePage, ArticleSelectionPage, ArticlePage } from './pages/page_index.js'


function App() {
  return (
    <Router>
      <div className="App">
        
        <Routes>
          <Route exact path='/' element={<HomePage />} />
          <Route path='/articles' element={<ArticleSelectionPage />} />
          <Route path='articles/:id' element={<ArticlePage />} />
        </Routes>
        
      </div>
    </Router>
  );
}

export default App;
