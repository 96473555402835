import './booknowbutton.css';
import { BookingForm } from '../component_index';
import React, {useState} from 'react'

const BookNowButton = ( { text } ) => {
  const [isFormOpen, setIsFormOpen] = useState(false);
  return (
    <div className='dcs__booknowbutton'>
      <h3>{text}</h3>
      <div className='dcs__booknowbutton-container'>
        <button onClick={() => setIsFormOpen(true)}>BOOK NOW</button>
      </div>
      <BookingForm open={isFormOpen} onClose={() => setIsFormOpen(false)} />
    </div>
  )
}

export default BookNowButton