import './footer.css'
import React, { useState } from 'react'
import { PrivacyPolicy, FAQs, BookingForm } from '../component_index';

const Footer = () => {
  const [isPolicyOpen, setIsPolicyOpen] = useState(false);
  const [isFAQsOpen, setIsFAQsOpen] = useState(false);
  const [isFormOpen, setIsFormOpen] = useState(false);

  return (
    <footer className='dcs__footer' id='contact'>

      <div className='dcs__footer-container'>
        <div className='dcs__footer-container_links'>
          <ul>
            <li onClick={() => setIsFAQsOpen(true)}>FAQs</li>
            <li onClick={() => setIsFormOpen(true)}>Booking Form</li>
            <li onClick={() => setIsPolicyOpen(true)}>Privacy Policy</li>
          </ul>
        </div>

        <div className='dcs__footer-container_contact'>
          <h4>Contact</h4>
          <p><a href='mailto:dancosgrove@dcstudio.co.nz'>dancosgrove@dcstudio.co.nz</a></p>
          <p>Te Awamutu, Waikato</p>
        </div>

        <FAQs open={isFAQsOpen} onClose={() => setIsFAQsOpen(false)} />
        <BookingForm open={isFormOpen} onClose={() => setIsFormOpen(false)} />
        <PrivacyPolicy open={isPolicyOpen} onClose={() => setIsPolicyOpen(false)} />
        

      </div>
      {/* include Image by VecMes on Freepik in the footer somewhere (background image) */}
    </footer>
  )
}

export default Footer