import './homepage.css'
import React, {useState, useEffect} from 'react'
import { Navbar, Footer, BookNowButton  } from '../../components/component_index'
import { LandingSection, AboutSection, SocialSection, AudioSampleSection, TestimonialSection, ArticleSection, GallerySection} from '../../sections/section_index'

const HomePage = () => {
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true)

  const handleScroll = () => {
    let currentScrollPos = window.pageYOffset;
    console.log(currentScrollPos)
    if (currentScrollPos > prevScrollPos) {
      setVisible(false)
    } else {
      setVisible(true)
    }

    setPrevScrollPos(currentScrollPos)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll)
  })
  return (
    <>
      <Navbar visible={visible} />
    <>
      <div className='dcs__homepage' id='dcs__homepage'>
        <LandingSection />
        <AboutSection />
        <SocialSection scrollPos={prevScrollPos}/>
        <AudioSampleSection />
        <TestimonialSection /> 
        <ArticleSection title='Stories from the Studio' homepageArticleSection={true} />
        <section className='dcs__ctasection' >
          <BookNowButton text="Ready to begin your recording journey?" />
        </section>
        <GallerySection />
        <Footer />
      </div>
    </>
    </>
  )
}

export default HomePage