import './testimonialsection.css'
import React from 'react'
import { TestimonialCarousel } from '../../components/component_index'

const TestimonialSection = () => {
  return (
    <section className='dcs__testimonialsection' id='testimonials'>
        <h1>What people say about us</h1>
        <div className='dcs__testimonialsection-container'>
            <TestimonialCarousel />
        </div>
    </section>
  )
}

export default TestimonialSection