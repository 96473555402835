import './gallerysection.css'
import React from 'react'
import { IGGallery } from '../../components/component_index'

const GallerySection = () => {
  return (
    <section className='dcs__gallerysection' id='gallery'>
            <h2>Gallery</h2>
            <div className='dcs__gallerysection-line largerline'/>
            <div className='dcs__gallerysection-line'/>
            <div className='dcs__gallerysection-component'>
                <IGGallery />
            </div>
    </section>
  )
}

export default GallerySection