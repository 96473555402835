import './testimonialcarousel.css'
import React, { useState } from 'react'
import { Testimonial } from '../component_index'
import { IoIosArrowDropleftCircle, IoIosArrowDroprightCircle} from 'react-icons/io'
import { GoPrimitiveDot } from 'react-icons/go'
import useFetch from '../../hooks/useFetch'
import { strapiUrl } from '../../quick_access_links'

// const testimonials = [<Testimonial name='Hermione Grainger' review='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et       dolore magna aliqua. In vitae turpis massa sed elementum tempus. Leo vel' date='12 October, 2022' image={testimonialPhoto} />,
//                       <Testimonial name='Harry Potter' review='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et       dolore magna aliqua. In vitae turpis massa sed elementum tempus. Leo vel' date='14 October, 2022' image={testimonialPhoto} />,
//                       <Testimonial name='Ron Weasley' review='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et       dolore magna aliqua. In vitae turpis massa sed elementum tempus. Leo vel' date='15 October, 2022' image={testimonialPhoto} />,]


const TestimonialCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  // This will fetch the testimonials from strapi
  const { loading, error, data } = useFetch('https://dcstudiobackend-production.up.railway.app/api/testimonials?populate=%2A');
    if (loading) return <p>Loading...</p>
    if (error) return <p>Error</p>

  console.log(data)
  const testimonialData = data.data 

  const testimonials = testimonialData.map((testimonial) => (
    <Testimonial  name={testimonial.attributes.name} review={testimonial.attributes.testimonial} 
                  date={testimonial.attributes.date} image={strapiUrl + testimonial.attributes.photo.data.attributes.url} /> 
  ))


  const prevTestimonial = () => {
    const isFirstTestimonial = currentIndex === 0;
    const newIndex = isFirstTestimonial ? testimonials.length - 1 : currentIndex - 1; 
    setCurrentIndex(newIndex);
  }
  const nextTestimonial = () => {
    const isLastTestimonial = currentIndex === testimonials.length - 1;
    const newIndex = isLastTestimonial ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  }

  const clickToTestimonial = (newIndex) => {
    setCurrentIndex(newIndex);
  }


  
  return (
    <div className='dcs__testimonialcarousel'>
      <div>{testimonials[currentIndex]}</div>
      <div className='dcs__testimonialcarousel-navdots'>
        <div className='dcs__testimonialcarousel-navdots_leftarrow' onClick={() => {prevTestimonial()}} ><IoIosArrowDropleftCircle /></div>
        
        <div className='dcs__testimonialcarousel-navdots_dots'>
            {testimonials.map((testimonial, testimonialIndex) => (
              currentIndex === testimonialIndex ? (
                <div 
                key={testimonialIndex} 
                onClick={() => {clickToTestimonial(testimonialIndex)}} className='dcs__testimonialcarousel-navdots_dots-single selected'>
              <GoPrimitiveDot /></div>) : <div 
            key={testimonialIndex} 
            onClick={() => {clickToTestimonial(testimonialIndex)}} className='dcs__testimonialcarousel-navdots_dots-single'>
              <GoPrimitiveDot /></div>)
          )}
        </div>
        
        <div className='dcs__testimonialcarousel-navdots_rightarrow' onClick={() => {nextTestimonial()}} ><IoIosArrowDroprightCircle /></div>
      </div>
    </div>
  )
}

export default TestimonialCarousel