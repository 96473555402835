import './navbar.css'
import logo from '../../assets/logo-whitetext-circle.png'
import React, {useState} from 'react'
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri'
import 'animate.css'




const Navbar = ( visible ) => {

  const [toggleMenu, setToggleMenu] = useState(false)


  const Menu = () => (
    <>
      <ul>
        <li><a href='/#about' onClick={() => setToggleMenu(false)}>About</a></li>
        <li><a href='/#audiosamples' onClick={() => setToggleMenu(false)}>Audio Samples</a></li>
        <li><a href='/#testimonials' onClick={() => setToggleMenu(false)} >Testimonials</a></li>
        <li><a href='/#storiesfromthestudio' onClick={() => setToggleMenu(false)} >Blog</a></li>
        <li><a href='/#gallery' onClick={() => setToggleMenu(false)} >Gallery</a></li>
        <li><a href='/#contact' onClick={() => setToggleMenu(false)} >Contact</a></li> 
      </ul>
      </>
    )
  
  return (
    <div className={`dcs__navbar ${visible.visible ? 'reveal' : 'hide'} `}>
      <div className='dcs__navbar-links'>
        <div className='dcs__navbar-links_logo'>
          <a href='/#'>
            <img src={logo} alt="DC Studios logo in circle border" />
          </a>
        </div>
        <div className='dcs__navbar-links_nav'>
          <Menu />
        </div>
        <div className='dcs__navbar-links_menu '>
          {toggleMenu
            ? <RiCloseLine color='#fff' size={27} onClick={() => setToggleMenu(false)} />
            : <RiMenu3Line color='#fff' size={27} onClick={() => setToggleMenu(true)} />
          }
          {toggleMenu && (
            <div className='dcs__navbar-links_menu-container'>
              <div className='dcs__navbar-links_menu-container_links animate__animated animate__slideInTop'>
                <Menu />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Navbar