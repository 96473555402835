import './articlesection.css'
import React from 'react'
import { Link } from 'react-router-dom'
import useFetch from '../../hooks/useFetch.js'
import { SmallArticleTile } from '../../components/component_index'
import { strapiUrl } from '../../quick_access_links'


// replace this with a fetch request to Strapi and hopefully objects come back in an array
// const articles = [{id: 2, title: "Test Title Numero Tres", date: new Date('2022-10-12'), image: AboutPhoto},
//                 {id: 1, title: "Test Title Numero Uno", date: new Date('2022-10-28'), image: AboutPhoto}, 
//                 {id: 3, title: "Test Title Numero Dos", date: new Date('2022-10-16'), image: AboutPhoto} ]

const ArticleSection = ({ title, idCheck, homepageArticleSection }) => {

    const { loading, error, data } = useFetch('https://dcstudiobackend-production.up.railway.app/api/articles?populate=%2A');
    if (loading) return <p>Loading...</p>
    if (error) return <p>Error</p>

    const articles = data.data;
    const displayArticles3 = [];
    const displayArticles4 = [];
    let maxLength = 3;
    // Takes the article array, and sorts them by date (newest first)
    const sortedArticles = articles.sort((a, b) => new Date(b.attributes.date) - new Date(a.attributes.date));
    sortedArticles.length > 4 ? maxLength = 4 : maxLength = 3; 

    console.log(sortedArticles)
    // Takes the 3 most recent articles and adds them to an array to display in the Article Section
    for (let i = 0; i < 3; i++) {
        displayArticles3.push(sortedArticles[i])
    }
    // Takes the 4 most recent articles and adds them to an array to display in the Article Section
    for (let i = 0; i < maxLength; i++) {
        displayArticles4.push(sortedArticles[i])
    }
    console.log(displayArticles3)
    console.log(displayArticles4)

    return (
        <section className='dcs__articlesection' id='storiesfromthestudio' data-testid="dcs__articlesection">

            <div className='dcs__articlesection-content'>
                <h2>{title}</h2>
                <a href='/articles'><h4>SEE ALL</h4></a>
            </div>

            <div className='dcs__articlesection-container' data-testid="dcs__articlesection-container">
                {/* Maps through the array that contains the articles to display
                and adds their props to the Small Article Tile Object*/}
                {/* homepageArticleSection check will prevent the same article card 
                being displayed below the article and will also only render 3 articles each time */}
                {homepageArticleSection === true 
                ? displayArticles3.map(article => (
                    article === true ?
                    <Link to={`/articles/${article.id}`}>
                            <SmallArticleTile
                            key={article.id}
                            title={article.attributes.title}
                            date={article.attributes.date}
                            image={strapiUrl + article.attributes.image.data[0].attributes.url}
                            id={article.id}
                            alttext={article.attributes.alttext} 
                            
                            />
                        </Link>
                        : <div>Coming soon</div>
                ))
                : displayArticles3.map(article => (
                    (idCheck === article.id) 
                    ?   null
                    :   
                    article === true ?
                    <Link to={`/articles/${article.id}`}>
                            <SmallArticleTile
                            key={article.id}
                            title={article.attributes.title}
                            date={article.attributes.date}
                            image={strapiUrl + article.attributes.image.data[0].attributes.url}
                            id={article.id}
                            alttext={article.attributes.alttext} />
                        </Link>
                        : <div>Coming Soon</div>
                ))}

            </div>

            <div className='dcs__articlesection-seeallbutton'>
                <a href='/articles'><h4>SEE ALL</h4></a>
            </div>
        </section>
    )
}

export default ArticleSection