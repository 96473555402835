import './faqs.css'
import React from 'react'
import ReactDOM from 'react-dom'
import logo from '../../assets/logo-whitetext-circle.png'
import { RiCloseFill } from 'react-icons/ri'
import 'animate.css'

// Imports the isOpen state and onClose functions
const FAQs = ({ open, children, onClose  }) => {
  // If closed return nothing
  if (!open) return null

  // Renders the component into a completely separate div (#portal) in the index.js file
  // **Retains parent - child relationship because of createPortal 
  return ReactDOM.createPortal(
    <div className='dcs__faqs'>
      <div className='dcs__faqs-blur' />
      <div className='dcs__faqs-container animate__animated animate__zoomIn'>
        <RiCloseFill onClick={onClose}/>
        <img src={logo} alt='DC Studio logo' />
        <h1>FAQs</h1>

        <h2>What style of music do you specialise in?</h2>
        <p>Although I{`'`}m not limited to, I mainly work with country artists.</p>

        <h2>Do I need to provide the musicians?</h2>
        <p>Not all. I can play the guitars, bass, backing vocals etc. I also have access to world class musicians should they be needed on your project.</p>
        
        <h2>Can I use my own musicians?</h2>
        <p>Yes. As long as they are right for the job.</p>

        <h2>Do you use real drums?</h2>
        <p>Depending on the project. When it comes to demos I may programme MIDI drums but when it comes to a professional release real drums will be used.</p>
                
        <h2>Do I have to record original material?</h2>
        <p>No you can record covers but you will have to purchase an APRA license to do so. I can help put you in touch with the right people.</p>

        <h2>I live far away. Can I record my vocals remotely?</h2>
        <p>Although I would prefer to have you in the studio to record vocals, if this is not possible, you may record you vocals at a studio closer to you and have them sent to me.</p>

        <h2>I have original music but no idea what to do next?</h2>
        <p>No worries! All I require is a rough demo with guitar or piano and vocals and I can start building the track from there!</p>
      </div>
    </div>,

    document.getElementById('portal')
  )
}

export default FAQs