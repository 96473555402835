import './privacypolicy.css'
import React from 'react'
import ReactDOM from 'react-dom'
import logo from '../../assets/logo-whitetext-circle.png'
import { RiCloseFill } from 'react-icons/ri'
import 'animate.css'

// Imports the isOpen state and onClose functions
const PrivacyPolicy = ({ open, children, onClose  }) => {
  // If closed return nothing
  if (!open) return null

  // Renders the component into a completely separate div (#portal) in the index.js file
  // **Retains parent - child relationship because of createPortal 
  return ReactDOM.createPortal(
    <div className='dcs__privacypolicy'>
      <div className='dcs__privacypolicy-blur' />
      <div className='dcs__privacypolicy-container animate__animated animate__zoomIn'>
        <RiCloseFill onClick={onClose}/>
        <img src={logo} alt='DC Studio Logo' />
        <h1>Privacy Policy</h1>
        <p>We collect personal information from you, including information about your:<br />

          <li>Name</li>
          <li>Contact Information</li>
          We collect your personal information in order to:<br />
          <li>Communicate with customers looking to record at DC Studio</li>
          Providing some information is optional. If you choose not to enter a name and an e-mail address, we'll be unable to provide our services.
          You have the right to ask for a copy of any personal information we hold about you, and to ask for it to be corrected if you think it is wrong. If you would like to ask for a copy of your information, or to have it corrected, please contact us at dancosgrove@dcstudio.co.nz.
        </p>
      </div>
    </div>,

    document.getElementById('portal')
  )
}

export default PrivacyPolicy