import './articleselectionpage.css'
import React from 'react'
import useFetch from '../../hooks/useFetch'
import { Navbar, Footer, SpotlightArticle, MediumArticleTile } from '../../components/component_index'
import { SocialSection } from '../../sections/section_index'

const ArticleSelectionPage = () => {
  const { loading, error, data } = useFetch('https://dcstudiobackend-production.up.railway.app/api/articles?populate=%2A');
  if (loading) return <p>Loading...</p>
  if (error) return <p>Error</p>

const articles = data.data;

const sortedArticles = articles.sort((a, b) => new Date(b.attributes.date) - new Date(a.attributes.date));
console.log(sortedArticles)


  return (
    <div className='dcs__articleselectionpage'>
      <Navbar visible={true}/>
      <div className='dcs__articleselectionpage-container'>

        <div className='dcs__articleselectionpage-container_heading'>
          <div className='dcs__articleselectionpage-container_heading-title'>
            <h1>Stories from the Studio</h1>
            <div className='dcs__articleselectionpage-container_heading-title_line'/>
          </div>
          <div className='dcs__articleselectionpage-container_heading-line' />
        </div>

        {/* The idea here is to map through sorted articles and have 
        the first(latest published) display as the spotlight article and the 
        rest display as Medium Article Tiles below in order from newest to oldest.
        */}
        {sortedArticles.map(article => (
          sortedArticles[0] === article
          ? <SpotlightArticle key={article.id} date={article.attributes.date} id={article.id} alttext={article.attributes.alttext}
                              body={article.attributes.body} title={article.attributes.title} image={article.attributes.image.data[0].attributes.url} /> 
          : <MediumArticleTile  key={article.id} date={article.attributes.date} id={article.id} alttext={article.attributes.alttext}
                                body={article.attributes.body} title={article.attributes.title} image={article.attributes.image.data[0].attributes.url}/>
          ))}   
      </div>
      <SocialSection />
      <Footer />
    </div>
  )
}

export default ArticleSelectionPage