import './iggallery.css'
import React from 'react'
import { InstagramGallery } from 'instagram-gallery'

const IGGallery = () => {
  return (
    <InstagramGallery accessToken='IGQVJXN0I0Y1dKVWtEbHljVjR2YlRSTlJMZA1E5ZAzNPVkNBSDBpTWFKS3FYTW1ZAamdsaHhsUmx1YWZA4N3EtWFM5Qnczb2FabFQ3bW9OTjJVY1dfeEFLWU9MN244dC1aRmRwY2VDMDJrRG9IMEpmU1NVSAZDZD' count={8} />
  )
}

export default IGGallery 