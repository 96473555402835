import './aboutsection.css'
import aboutPhoto from '../../assets/aboutPhoto.jpg'
import React from 'react'
import useFetch from '../../hooks/useFetch'

const AboutSection = () => {
  // Retrieves about decription from strapi and store in a variable
  const { loading, error, data } = useFetch('https://dcstudiobackend-production.up.railway.app/api/about-description');
    if (loading) return <p>Loading...</p>
    if (error) return <p>Error</p>
    const aboutDescription = data.data.attributes.text

  return (
    <section className='dcs__aboutsection' id='about'>
      <div className='dcs__aboutsection-container'>
        <div className='dcs__aboutsection-container_image'>
          <div className='dcs__aboutsection-container_image-line2' />
          <div className='dcs__aboutsection-container_image-line' />
          <img src={aboutPhoto} alt='Dan Cosgrove playing a show' />
        </div>
        <div className='dcs__aboutsection-container_content'>
          <h2>Dan Cosgrove</h2>
          <h3>Studio Engineer | Professional Guitarist</h3>
          <p>{aboutDescription}</p>
        </div>
      </div>
    </section>
  )
}

export default AboutSection