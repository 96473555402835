import './articlepage.css'
import React from 'react'
import { strapiUrl } from '../../quick_access_links'
import { useParams } from 'react-router-dom'
import useFetch from '../../hooks/useFetch'
import { Navbar, Footer } from '../../components/component_index'
import { ArticleSection } from '../../sections/section_index'

const ArticlePage = () => {
  const { id } = useParams()
  const { loading, error, data } = useFetch('https://dcstudiobackend-production.up.railway.app/api/articles/' + id + '?populate=%2A')

  if (loading) return <p>Loading...</p>
  if (error) return <p>Error</p>

  console.log(data.data)
  let articleId = data.data.id;
  let date = data.data.attributes.date;
  let title = data.data.attributes.title
  let image = strapiUrl + data.data.attributes.image.data[0].attributes.url
  let alttext = data.data.attributes.alttext
  let body = data.data.attributes.body

  return (
    <>
    <div className='dcs__articlepage'>
      <Navbar visible={true} />
      <div className='dcs__articlepage-container'>

        <h2>{title}</h2>
        <div className='dcs__articlepage-container_content-line' />
        <img src={image} alt={alttext} />

        <div className='dcs__articlepage-container_content'>
          <h5>{date}</h5>
          <div className='dcs__articlepage-container_content-line' />
          <p>{body}</p>
        </div>

      </div>

    </div>
  <ArticleSection title='Recommended Articles' idCheck={articleId}/>
  <Footer />
  </>
  )
}

export default ArticlePage;