import './spotlightarticle.css'
import { strapiUrl } from '../../quick_access_links'
import React from 'react'
import { Link } from 'react-router-dom'

const SpotlightArticle = ( { date, body, title, image, id, alttext } ) => {
  console.log(image)
  return (
    <div className='dcs__spotlightarticle'>
      <div className='dcs__spotlightarticle-container'>

      <img src={strapiUrl + image} alt={alttext}/>

        <div className='dcs__spotlightarticle-container_content'>
          <h5>{date}</h5>
          <div className='dcs__spotlightarticle-container_content-line1' />
          <Link to={`/articles/${id}`}><h2>{title}</h2></Link>
          <div className='dcs__spotlightarticle-container_content-line2' />
          <p>{body.substring(0,200) + '...'}</p>
        </div>
      </div>
    </div>
  )
}

export default SpotlightArticle