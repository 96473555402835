import './testimonial.css'
import React from 'react'

const Testimonial = ( { name, review, date, image } ) => {
  return (
      <div className='dcs__testimonial'>
        <div className='dcs__testimonial-content'>
            <img src={image} alt={name} />
            <p><span>"</span>{review}</p>
        </div>
        <div className='dcs__testimonial-details'>
            <h4>{name}</h4>
            <p>{date}</p>
        </div>
    </div>
  )
}

export default Testimonial